.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  //padding: 16px 256px;
  padding: 16px;
}

.navContainer {
  //  margin-left: auto;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;
}

.brandLogoContainerContainer {
  display: flex;
  flex-wrap: nowrap;
}

.brandingTextDivider {
  background-color: #c4cdd5;
  width: 1px;
  height: 100%;
  margin: 0px 16px;
  height: 56px;
}

.brandingTextContainer {
  display: flex;
  align-items: flex-end;
  font-size: 15px;
  color: #212b36;
  font-weight: 500;
}

.navContainer li {
  margin-left: 35px;
}

.navContainer li a {
  color: #212b36;
  font-size: 14px;
}

.startApplicationBtn {
  height: 45px;
  font-size: 14px;
  color: white;
}

.navbarLink {
  display: flex;
  align-items: center;
  height: 30px;
}

.navbarLinkIconStyle {
  color: #637381;
  margin-right: 8px;
  width: 20px;
  max-height: 20px;
}

.startApplicationBtn {
  width: 177px;
  color: white;
  cursor: default;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuBtn {
  display: none;
  margin-right: 24px;
}

.navDrawer {
  display: none;
}

.drawerNav {
  list-style-type: none;
  padding: 0;
}

.drawerNav li {
  padding: 10px 0px 10px 0px;
}

.drawerNav li a {
  color: #212b36;
  font-size: 14px;
}

.drawerClose {
  margin-right: 24px;
}

@media screen and (max-width: 767px) {
  .headerContainer {
    // padding: 16px 24px 16px 24px;
    padding: 16px;
  }

  .brandingTextContainer {
    display: none;
  }

  .hidden {
    display: none;
  }

  .menuBtn {
    display: inline;
  }

  .navDrawer {
    display: block;
  }

  .navbarLinkText {
    display: none;
  }
  .navContainer li {
    //margin-left: 0px;
  }
}
