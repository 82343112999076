html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-card-head-title {
  white-space: normal !important;
}

.ant-select-item-option-content {
  white-space: normal !important;
}

.ant-tabs-nav-operations {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .ant-tabs-tab-btn > span {
    padding: 0 12px 0 12px;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400;
  background-color: #ececee;
  cursor: not-allowed;
  * {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 0px;
}

#credit-calculator {
  a {
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline !important;
    }
  }

  * {
    &:focus {
      outline-width: 3px;
      outline-style: dashed;
      outline-color: rgb(94, 158, 214);
    }
  }
}
