.creditCalculatorContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  .calculatorSearchContainer {
    width: 100vw;
    max-width: 100%;
  }
}

.notFoundContent {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.searchDescription {
  color: #4e5b66;
  line-height: 20px;
  margin-bottom: 32px;
}

.searchIllustrationIcon {
  margin-top: 74px;
  margin-bottom: 40px;
}
