.rootTab {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 65px;
  > div {
    width: 100%;
  }
}

.tabContent {
  padding: 0 24px 0 24px;
}

.tabpane {
  width: 100%;
}

.tabBadge {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 12px;
  padding: 2px 3px 2px 3px;
  margin-left: 5px;
}

.resultListContainer {
  background-color: inherit !important;
}

.resultNotFoundContainer {
  margin-top: 65px;
  padding: 0px 16px 0px 16px;
}

.tabContentIntro {
  width: 100%;
  padding: 16px;
}

.tabTooltipIcon {
  max-height: 20px;
  max-width: 20px;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .tabContent {
    font-size: 13px;
  }

  .tabBadge {
    font-size: 11px;
  }

  .resultListContainer {
    margin-top: 0px;
  }
}
