.mobileCreditItemDetailContainer {
  border-bottom: 1px solid #c4cdd5;
  padding: 16px;
}

.mobileArrowDown {
  margin: 4px 0px 4px 0px;
}

.mobileHeart {
  position: absolute;
  top: 8px;
  right: 16px;
}

.sourceSideContainer {
  padding: 20px;
}

.targetSideContainer {
  padding: 20px;

  border: 1px solid transparent;
  border-bottom: 1px solid #c4cdd5;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  &:hover {
    border: 1px solid #c4cdd5;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: 0px 0px 4px 2px #f2f2f2;
  }
}

.topTitle {
  font-weight: 500;
}

.bottomDescription {
  font-size: 12px;
  color: #637381;
  margin-top: 1rem;
}

.bottomDescriptionNoMarginTop {
  font-size: 12px;
  color: #637381;
}

.iconStyle {
  color: #637381;
  margin-top: 3px;
}

.heartIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  bottom: 6px;

  &:hover {
    background-color: #ececee;
    border-radius: 50%;
  }

  .heartIcon {
    &:hover {
      cursor: pointer;
    }
  }
}

.heartIconOutline {
  color: #637381;
}

.targetSubjectExternalLinkIcon {
  height: 16px;
  margin-left: 6px;
  margin-bottom: 4px;
}

.creditPointsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.creditPoints {
  font-size: 12px;
  color: #637381;
}

.inlineIcon {
  height: 12px;
  width: 12px;
}

.cohortRow {
  margin-top: 1rem;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  margin-right: 20px;
}

.cohort,
.additionalDetails {
  color: #212b36;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  // #017BCE
  .infoFilledIcon {
    filter: invert(23%) sepia(82%) saturate(2733%) hue-rotate(189deg)
      brightness(103%) contrast(99%);
  }
  .caption {
    font-size: 12px;
    color: #212b36;
    white-space: nowrap;
  }
}

.targetSubjectRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 500;
  gap: 4px;
}

.creditItemBlockFooter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}

.articulationDetailsMarkupContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
}

.clearButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
}
