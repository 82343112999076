.resultNotFoundContainer {
  display: flex;
  flex-direction: column;
  text-align: center;

  .notFoundText {
    margin-top: 30px;
    font-size: 20px;
  }

  .notFoundDescription {
    margin-top: 16px;
    font-size: 14px;
  }

  .searchIllustrationIcon {
    align-self: center;
    color: #ccc;
  }
}
