.navContainer {
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
}
.helpContainer {
  width: 100%;
  padding: 16px;

  .helpMainTitle {
    margin-top: 65px;
    font-size: 44px;
    text-align: center;
    margin-bottom: 65px;
  }

  .helpContent {
    margin-bottom: 40px;
  }

  .helpContentTitle {
    font-size: 32px;
  }

  .collapsePanelHeader {
    font-size: 20px;
  }

  .collapsePanelContent {
    padding-bottom: 28px;
  }

  @media screen and (max-width: 767px) {
    .helpMainTitle {
      font-size: 32px;
    }

    .helpContentTitle {
      font-size: 26px;
    }
  }
}
