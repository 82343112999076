.searchContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 56px 16px;

  .searchTitle {
    font-size: 44px;
    line-height: 52px;
    margin-bottom: 16px;
  }
}
