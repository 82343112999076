.layoutRoot {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footerContainer {
  margin-top: auto;
  background: #f8f9fa;
  padding: 28px;
  width: 100%;
}

.contentContainer {
  width: 100%;
  min-height: 647px;
}

.headerContainer {
  // height: calc(96px + 32px);
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  background: #fff;
  // padding: 0px 256px;
}

.ribbonContainer {
  background-color: black;
}
.ribbon {
  padding: 8px 16px;
  font-size: 12px;
  a {
    color: white;
  }
}

@media screen and (max-width: 767px) {
  .headerContainer {
    position: fixed;
    z-index: 10;
    // padding: 0px 0px;
  }

  .contentContainer {
    // padding-top: calc(96px + 32px);
  }
}

.skipToContent {
  background: white;
  box-shadow: 0px 0px 4px 2px #f2f2f2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  left: 50%;
  padding: 4px 10px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}
.skipToContent:focus {
  transform: translateY(0%);
}
