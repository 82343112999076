.errorMessageContainer {
  max-width: 530px;
  margin-top: 120px;
  text-align: center;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  .title {
    font-size: 44px;
    line-height: 115%;
  }

  .homeLink {
    margin-top: 16px;
    font-size: 20px;

    &.homeLink a {
      color: #006fbc;
    }
    :visited {
      color: #044069;
    }
    :active {
      color: #044069;
    }
    :selected {
      color: #044069;
    }
  }

  @media screen and (max-width: 767px) {
    .title {
      font-size: 26px;
    }
  }
}
