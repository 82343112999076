.savedCreditContainer {
  width: 100%;

  .mobileLineSeparator {
    border-bottom: 1px solid #c4cdd5;
  }

  .savedItems {
    // padding: 0px 16px 0px 16px;
  }

  .title {
    font-size: 44px;
    margin-top: 24px;
    margin-bottom: 18px;
    padding: 0px 16px 0px 16px;
  }

  .navContainer {
    padding: 16px;
  }

  .groupedCreditContainer {
    margin-bottom: 36px;
  }

  .sourceInstitutionLabel {
    font-size: 20px;
    margin-bottom: 18px;
    padding: 0px 16px 0px 16px;
  }

  .backLink {
    color: #637381;
  }

  .backIcon {
    position: relative;
    top: 4px;
    margin-right: 16px;
  }

  @media screen and (max-width: 767px) {
    .groupedCreditContainer {
      margin-bottom: 16px;
    }

    .sourceInstitutionLabel {
      margin-bottom: 16px;
    }
  }
}

.noSavedCreditContainer {
  // margin-top: 181px;
  text-align: center;
}
