.creditItemDetailsContainer {
  width: 100%;

  .navContainer {
    display: flex;
    align-items: center;
    padding: 16px;
    width: 100%;
  }

  .mobileCreditDetails {
    border-top: 1px solid #c4cdd5;
    border-bottom: 1px solid #c4cdd5;
    padding-bottom: 16px;
  }

  .saveCreditBtnContainer {
    margin-left: auto;

    .saveCreditBtn {
      display: flex;
      font-size: 14px;
      color: #212b36;
    }
  }

  .iconStyleOutline {
    color: #637381;
  }

  .iconStyle {
    position: relative;
    top: 3px;
  }

  .creditPointsContainer {
    margin-top: 55px;
    text-align: center;

    .creditPointstitle {
      font-size: 20px;
    }

    .creditPoints {
      font-size: 56px;
    }

    @media screen and (max-width: 767px) {
      .creditPoints {
        font-size: 32px;
      }
    }
  }

  .conditionContainer {
    margin-top: 60px;
    padding: 16px;

    .conditionCol {
      padding-right: 60px;
    }

    .conditionTitle {
      color: #637381;
      margin-bottom: 16px;
    }

    .conditionList {
      padding: 13px 0px 0px 32px;
    }
  }

  .creditDetailsContainer {
    // margin-top: 20px;
  }

  .creditBlockDetailsContainer {
    margin-bottom: 16px;
  }

  .bottomDescription {
    font-size: 12px;
    color: #637381;
  }

  .creditBlockPointsCol {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .creditBlockLogo {
    margin-top: 6px;
  }

  .creditBlockHeadingCompletionCol {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media screen and (max-width: 767px) {
    .creditBlockPointsCol {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .creditBlockHeadingCompletionCol {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .topHeading {
      margin-top: 8px;
    }

    .creditBlockLogo {
      margin-bottom: 8px;
    }

    .creditBlockDetailsContainer {
      padding: 0px 16px 0px 16px;
    }

    .creditDetailsContainer {
      margin-top: 40px;
    }
  }

  .articulationHeadingContainer {
    padding: 20px;
    white-space: normal;
    font-weight: normal;

    .articulationMainHeading {
      font-size: 26px;
    }

    .articulationHeadingBottomDescription {
      color: #637381;
    }

    .articulationHeadingCompletion {
      margin-top: 24px;
    }

    .arrowIconCol {
      display: flex;
      justify-content: center;
    }

    .arrowDown {
      display: none;
      margin: 12px 0px 12px 0px;
    }

    .arrowRight {
      display: block;
    }

    .arrowIconContainer {
      width: 58px;
      height: 58px;
      background-color: #d7d9db;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .arrowIcon {
        color: #212b36;
      }
    }

    .cohortRow {
      margin-top: 1rem;
      gap: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
    }

    .cohortItem,
    .additionalDetailsItem {
      color: #212b36;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      font-size: 16px;
      // #017BCE
      .infoFilledIcon {
        filter: invert(23%) sepia(82%) saturate(2733%) hue-rotate(189deg)
          brightness(103%) contrast(99%);
      }
    }
  }

  @media screen and (max-width: 767px) {
    .articulationHeadingContainer {
      text-align: center;

      .articulationHeadingCompletion {
        margin-top: 0px;
      }

      .arrowIconContainer {
        width: 32px;
        height: 32px;
      }

      .arrowDown {
        display: block;
      }

      .arrowRight {
        display: none;
      }

      .articulationMainHeading {
        font-size: 20px;
      }
    }
  }
}

.hidden {
  display: none;
}

.targetSubjectExternalLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
