.title {
  font-size: 26px;
}

.description {
  margin-top: 16px;
}

.heartIcon {
  position: relative;
  top: 3px;
}

.homeLink {
  margin-top: 50px;
  font-size: 14px;

  &.homeLink a {
    color: #007ace;
  }
}
