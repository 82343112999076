.commonErrorContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;

  .commonErrorContent {
    max-width: 400px;
    text-align: center;
  }

  .logo {
    margin-top: 120px;
  }

  .errorMessage {
    margin-top: 80px;
    font-size: 44px;
    line-height: 115%;
  }

  .refreshMessage {
    margin-top: 16px;
    color: #007ace;
    font-size: 20px;
  }

  @media screen and (max-width: 767px) {
    .errorMessage {
      font-size: 26px;
    }
  }
}
