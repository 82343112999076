.ant-badge-count {
  background-color: #000;
}

.ant-collapse {
  background-color: transparent !important;
}

.ant-collapse-header {
  padding: 28px 0px 28px 0px !important;

  &:hover {
    color: #000 !important;
  }
}
.ant-collapse-content-box {
  padding: 0px !important;
}

.loading-outlined {
  color: #000;
}

.heart-icon-filled {
  color: #000;
  fill: #000;
}
